import Button from 'components/Button';
import { LangForm } from 'components/Form';
import { Input, FormRow, InputWithSelect, SmallFormDescription } from 'components/FormControls';
import Modal from 'components/Modal';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { Field } from 'react-final-form';
import countrySources from 'shared/api/reference/country';
import { LANGUAGES } from 'shared/constants/LANGUAGES';
import { renderCountries } from 'shared/helpers/optionsRenderers';
import {
  composeValidators,
  required,
  minValue,
  maxValue,
  validSpecialChars,
} from 'shared/helpers/validations';
import { SlugGenerate } from 'shared/ui/slug-generate';
import { generateSlug } from 'shared/ui/slug-generate/lib';
import { ModalFunctions } from '../../../../../interfaces/modal';
import { LocationsFormSubmitData } from '../types';
import Styled from './styles';

interface FormProps {
  isMobile?: boolean;
  title: string;
  onChangeLng?: (lng: string) => void;
  closeModal: ModalFunctions['closeModal'];
  isEdit?: boolean;
  state?: object;
  onSubmit?: (data: LocationsFormSubmitData) => void;
  onSuccess?: (data: object) => void;
  stashLanguages?: boolean;
  initialValues?: object;
  openDeleteForm?: () => void;
  isSubmitting?: boolean;
}

export const LocationForm: React.FC<FormProps> = ({
  title,
  isMobile,
  onChangeLng,
  closeModal,
  openDeleteForm,
  isSubmitting,
  isEdit,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <LangForm
      {...rest}
      withoutNotification
      onChangeLng={onChangeLng}
      render={({ handleSubmit, switcher, i18n, values, form, errors }) => {
        const createSlug = async () => {
          const slug = await generateSlug({ values });
          form.change(`${i18n.language}.slug`, slug);
        };

        return (
          <form onSubmit={handleSubmit}>
            {isMobile ? (
              <Styled.Header>
                <Styled.Title>{t(title)}</Styled.Title>
              </Styled.Header>
            ) : (
              <Modal.Title>{t(title)}</Modal.Title>
            )}
            <Styled.Content>
              <FormRow>{switcher}</FormRow>
              <Field name={`${i18n.language}.id`} component="input" type="hidden" />
              {i18n.language !== LANGUAGES.RU ? null : (
                <FormRow>
                  <Field name={`${i18n.language}.country_id`} validate={required}>
                    {({ input, meta }) => (
                      <InputWithSelect
                        isAsync
                        route={countrySources}
                        label={t('references:location.country')}
                        {...input}
                        meta={meta}
                        language={i18n.language}
                        optionsRenderer={renderCountries}
                        searchQueryName="search_string"
                      />
                    )}
                  </Field>
                </FormRow>
              )}
              <FormRow>
                <Field
                  name={`${i18n.language}.name`}
                  validate={composeValidators(
                    required,
                    validSpecialChars(i18n.language),
                    minValue(2),
                    maxValue(40),
                  )}
                >
                  {({ input, meta }) => (
                    <Input label={t('references:location.name')} autoFocus {...input} meta={meta} />
                  )}
                </Field>
              </FormRow>

              {i18n.language !== LANGUAGES.RU ? null : (
                <>
                  <SlugGenerate
                    isEdit={isEdit}
                    values={values}
                    form={{ ...form, errors }}
                    createSlug={createSlug}
                  />

                  <FormRow>
                    <Field name={`${i18n.language}.code`} validate={composeValidators(required, minValue(2))}>
                      {({ input, meta }) => <Input label="Код региона" {...input} meta={meta} />}
                    </Field>
                    <SmallFormDescription>{t('references:subdomains.iso_code')}</SmallFormDescription>
                  </FormRow>
                </>
              )}
              <Styled.FooterContainer>
                <Modal.Footer>
                  {isEdit ? (
                    <>
                      <Button type="submit" disabled={isSubmitting}>
                        {t('forms:save')}
                      </Button>
                      <Button danger onClick={openDeleteForm} type="button">
                        {t('forms:delete')}
                      </Button>
                    </>
                  ) : (
                    <>
                      {isMobile && (
                        <Button transparent onClick={closeModal}>
                          {t('forms:cancel')}
                        </Button>
                      )}
                      <Button disabled={isSubmitting}>{t('forms:create')}</Button>
                    </>
                  )}
                </Modal.Footer>
              </Styled.FooterContainer>
            </Styled.Content>
          </form>
        );
      }}
    />
  );
};
